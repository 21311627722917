import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";
import { addDays } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { setSelectedDate, setSelectedLogsDate } from "../slice/DateFilterSlice";
import { Button, Fade, Grid, Paper, Popper, Typography } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { getMonthName } from "./dashboard/TransactionChart";
import { userRangeData } from "../slice/UserRangeSlice";
import { fetchTableData } from "../slice/TableSlice";
import { chargerLogsData } from "../slice/ChargerLogsSlice";
import { toast } from "react-toastify";

const NewDatePicker = ({ facilityId, userId, logs_date }) => {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedDate = useSelector((state) => logs_date ? state.datePicker.logsSelectedDate : state.datePicker.selectedDate);
  const currentChargerApi = useSelector((state) => state.chargerLogs.currentChargerApi)
  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      startDate: selectedDate?.start,
      endDate: selectedDate?.end,
      key: "selection",
    },
  ]);
  const datePickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDateFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeHandler = (item) => {
    setState([item.selection]);
  };

  const formatedDate = (start, end) => {
    const formatedStartDate =
      start.getDate() +
      "-" +
      getMonthName(start.getMonth()).substring(0, 3) +
      "-" +
      start.getFullYear();
    const formatedEndDate =
      end.getDate() +
      "-" +
      getMonthName(end.getMonth()).substring(0, 3) +
      "-" +
      end.getFullYear();
    return `${formatedStartDate} ~ ${formatedEndDate}`;
  };

  const toggleModal = (e) => {
    if (showDateFilter) cancelHandler();
    else setShowDateFilter((prev) => !prev);
    setAnchorEl(e.currentTarget);
  };

  const changeDateHandler = () => {
    const { startDate, endDate } = state[0];

    
    if (logs_date) {
      const maxEndDate = new Date(startDate);
      maxEndDate.setMonth(startDate.getMonth() + 1);

      if (endDate >= maxEndDate)
        return toast.error("Can't Select More Than One Month")
    }

    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);

    const startForDetails = new Date(startDate);
    startForDetails.setHours(23);
    let objj = {
      start: startDate,
      end: endDate,
      startForDetails,
    }

    dispatch(
      logs_date ?
        setSelectedLogsDate(objj)
        :
        setSelectedDate(objj)
    );

    setShowDateFilter(false);

    const startISOString = startForDetails.toISOString().split("T")[0];
    const endISOString = endDate.toISOString().split("T")[0];

    if (window.location.pathname === "/charging-stations") {
      dispatch(
        logs_date ?
        chargerLogsData({selectedChargerLink: currentChargerApi, startDate: startISOString, endDate: endISOString })
        :
        fetchTableData({
          stationId: facilityId,
          startDate: startISOString,
          endDate: endISOString,
        })
      );
    }

    if (window.location.pathname === "/user-management") {
      dispatch(
        userRangeData({
          userId: userId,
          startDate: startISOString,
          endDate: endISOString,
        })
      );
    }
  };

  const cancelHandler = () => {
    setState([
      {
        startDate: selectedDate.start,
        endDate: selectedDate.end,
        key: "selection",
      },
    ]);
    setShowDateFilter(false);
  };

  return (
    <Box>
      <Box
        onClick={toggleModal}
        padding={1.5}
        sx={{
          backgroundColor: showDateFilter ? "#e0e0e0" : "white",
          border: !showDateFilter
            ? "0.1px solid rgba(0,0,0,0.17)"
            : "0.1px solid rgba(0,0,0,0.87)",
          borderRadius: "4px",
        }}
        component="fieldset"
      >
        <Grid
          container
          alignItems="center"
          fontWeight={400}
          sx={{ cursor: "pointer" }}
        >
          <Grid item mr={1}>
            <Typography variant="subtitle" fontSize="14px">
              {formatedDate(selectedDate?.start, selectedDate?.end)}
            </Typography>
          </Grid>
          <CalendarMonthOutlinedIcon fontSize="small" />
        </Grid>
      </Box>
      <Popper
        id={"simple-popper"}
        open={showDateFilter}
        placement="bottom-start"
        anchorEl={anchorEl}
        transition
        sx={{ zIndex: "9999" }}
        ref={datePickerRef}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 0 15px 2px rgba(0,0,0,0.3)",
              }}
            >
              <Grid container flexDirection="column" padding={2}>
                <DateRangePicker
                  onChange={changeHandler}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={state}
                  direction="vertical"
                  maxDate={addDays(new Date(), 0)}
                />
                <Grid
                  container
                  sx={{
                    justifyContent: "flex-end",
                    paddingTop: "15px",
                    fontSize: "11px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={changeDateHandler}
                    size="small"
                    sx={{ mr: "10px", fontSize: "11px" }}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="contained"
                    onClick={cancelHandler}
                    size="small"
                    color="error"
                    sx={{ fontSize: "11px" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};
export default NewDatePicker;
