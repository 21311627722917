import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import api from "../../apiSetup/apiCall";
import { toast } from 'react-toastify';
import CloseIcon from "@mui/icons-material/Close";
import { compressImage } from "../../common";

const CustomFileUpload = ({
  label,
  required,
  col_size,
  is_error,
  value: propValue,
  api: url,
  disabled,
  onValueChange
}) => {
  const [helperText, setHelperText] = useState("");
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  const styles = {
    color: "var(--gray-500, #667085)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "18px",
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    let quality = 0.7;
    let compressedImage = file;
    if (file) {
      do {
        compressedImage = await compressImage(compressedImage, quality);
        quality -= 0.1;

      } while ((compressedImage.size / 1024) > 700);
      try {
        const formData = new FormData();
        formData.append('file', compressedImage);
        const response = await api.post(url.upload, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        onValueChange([...value, response?.data?.data]);
      } catch (error) {
        toast.error('Error Uploading File. Please retry!');
      }
    }
  };

  const removeUpload = async (event, fileUrl) => {
    try {
      const response = await api.put(url.delete, { url: fileUrl })
      onValueChange(value.filter(item => item !== fileUrl))
    }
    catch (error) {
      toast.error('Error Removing File . Please retry !')
    }
  }


  return (
    <Grid item xs={col_size}>
      <Typography style={{ fontSize: "14px", fontWeight: "600", lineHeight: "18px" }}>
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Typography>
      {
        value?.length > 0 &&
        <Grid container style={{ marginTop: "16px", gap: '10px' }} >
          {
            value.map((item, index) =>
              <Grid item key={index} position='relative' sx={{ border: '1px solid rgba(0,0,0,0.5)', borderRadius: '10px', width: '50px', height: '50px' }}>
                <img src={item} alt='profile_pic' style={{ borderRadius: '10px', width: '100%', height: '100%' }} />
                <IconButton
                  onClick={(e) => removeUpload(e, item)}
                  sx={{
                    position: "absolute",
                    top: "-6px",
                    right: "-6px",
                    color: "red",
                    padding: 0,
                    backgroundColor: 'white'
                  }}
                >
                  <CloseIcon sx={{ fontSize: "17px" }} />
                </IconButton>
              </Grid>
            )
          }
        </Grid>
      }
      <Grid style={{ marginTop: "16px" }}>
        <Button
          component="label"
          variant="outlined"
          startIcon={<UploadFileIcon />}
          style={styles}
        >
          Upload
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload}
            disabled={disabled}
            accept=".jpg, .jpeg, .png"
            helperText={
              <Typography
                variant="caption"
                color="error"
                style={{ marginTop: "5px", marginLeft: "0" }}
              >
                {helperText}
              </Typography>
            }
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomFileUpload;
